// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

/* You can add global styles to this file, and also import other style files */

@import "./assets/lcptracker-theme.scss";

// @tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 14px;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.custom-mat-dialog-container .mat-dialog-container {
  padding: 2.28rem;
  border-radius: 0.571rem;
  border: 0.071rem solid rgba(0, 0, 0, 0.12);
}