@use '@angular/material' as mat;

/* Color palettes used to generate angular material color theme */
$lcp-blue: (
  50: #e9edf6,
  100: #c8d3e9,
  200: #a3b6da,
  300: #7e98cb,
  400: #6282bf,
  // 5.46:1 best contrast #000
  500: #466cb4,
  // 5.16:1
  600: #375da7,
  // 6.39:1
  700: #2a4b8b,
  800: #233c6f,
  900: #1a2e57,
  A100: #c9d6ff,
  A200: #96afff,
  A400: #6388ff,
  // 6.49:1
  A700: #4975ff,
  // 5.27:1 best contrast #000
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$lcp-yellow: (
  50: #fef4e5,
  100: #fee4be,
  200: #fdd293,
  300: #fcc068,
  400: #fbb347,
  500: #f5a64d,
  600: #ed9c41,
  700: #e09037,
  800: #d68429,
  900: #be7524,
  // 5.75:1
  A100: #ffffff,
  A200: #ffe1d5,
  A400: #ffcebb,
  A700: #ffc3ac,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$lcp-warn: (
  50: #f6e0e4,
  100: #e7b3bc,
  200: #d88090,
  300: #c84d63,
  // 4.69:1 best contrast #000
  400: #bc2641,
  // 6.01:1
  500: #b00020,
  600: #a9001c,
  700: #a00018,
  800: #970013,
  900: #87000b,
  A100: #ffb3b6,
  A200: #ff8085,
  A400: #ff4d53,
  A700: #ff343b,
  // 5.8:1 best contrast #000
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* Colors to consume from the complete color palette*/

$primary: #466cb4;
$primaryVariant: #2A4B8B;
$secondary: #FAA527;
$secondaryVariant: #BE7524;

$error: #B00020;
$errorText: #B33505;
$successText: #30A216;
$neutralBlack: rgba(#231F20, 0.8);

/* Material colors */

$matGreyDarken1: #757575;
$matGreyDarken2: #616161;
$matGreyDarken3: #424242;
$matGreyDarken4: #212121;

/* Other colors defined in the color palette */
$lightBlue: #CED8EB;
$sideLineColor: #2e3f50;

$linkColor: #409AD2;

$backgroundLight: #FFFFFF;
$lightGray: #FAFAFA;
$matFilledBackground: #f5f5f5;
$gray: #EDEDED;
$matGray: #e0e0e0;
$matHoverFocused: #c6c6c6;
$matNeutralBorder: #8e8e8e;
$greyLighten1: #bdbdbd;
$matFormIcon: #757575;
$backgroundDark: #231F20;

/* Setting Material Typography

Headings ans their equivalents
+----------------------------------+---------------+------------------+
|           CSS class              |  Level name   |  Native elements |
+----------------------------------+---------------+------------------+
| .mat-h1 or .mat-headline         | headline      | <h1>             |
| .mat-h2 or .mat-title            | title         | <h2>             |
| .mat-h3 or .mat-subheading-2     | subheading-2  | <h3>             |
| .mat-h4 or .mat-subheading-1     | subheading-1  | <h4>             |
| .mat-h5                          | None          | <h5>             |
| .mat-h6                          | None          | <h6>             |
| .mat-body or .mat-body-1         | body-1        | Body text        |
| .mat-body-strong or .mat-body-2  | body-2        | None             |
| .mat-small or .mat-caption       | caption       | None             |
+----------------------------------+---------------+------------------+

The .mat-h5 and .mat-h6 styles don't directly correspond to a specific Material Design typography level.
The .mat-h5 style uses the body-1 level with the font-size scaled down by 0.83.
The .mat-h6 style uses the body-1 level with the font-size scaled down by 0.67.

However, the Typography levels not native in Material Desing have been defined with custom styles in the next lines above.

1. Transforming levels size font from px to Rem

+-------------+------------+-------------+
|   Element   | Size in px | Size in REM |
+-------------+------------+-------------+
| H1          |         96 |       6.857 |
| H2          |         60 |       4.286 |
| H3          |         48 |       3.429 |
| H4          |         34 |       2.429 |
| H5          |         24 |       1.714 |
| H6          |         20 |       1.429 |
| Subtitle 1  |         16 |       1.143 |
| Subtitle 2  |         14 |       1.000 |
| Body 1      |         14 |       1.000 |
| Body 2      |         12 |       0.857 |
| Button      |         14 |       1.000 |
| Caption     |         12 |       0.857 |
| Overline    |         10 |       0.714 |
+-------------+------------+-------------+

*/

/* 2. Defining custom h5, h6, subtitle 1, subtitle 2 and overline levels */

$subtitle-1: mat.define-typography-level($font-family: "Poppins",
    $font-weight: 400,
    $font-size: 1.143rem,
    $letter-spacing: normal,
  );

$subtitle-2: mat.define-typography-level($font-family: "Poppins",
    $font-weight: 500,
    $font-size: 1rem,
    $letter-spacing: normal,
  );

$custom-overline: mat.define-typography-level($font-family: "Poppins",
    $font-weight: 400,
    $font-size: 0.714rem,
    $letter-spacing: normal,
  );

$custom-h5: mat.define-typography-level($font-family: "Fira Sans Extra Condensed",
    $font-weight: 400,
    $font-size: 1.714rem,
    $letter-spacing: normal,
  );

$custom-h6: mat.define-typography-level($font-family: "Fira Sans Extra Condensed",
    $font-weight: 500,
    $font-size: 1.429rem,
    $letter-spacing: normal,
  );

/* header */
$tableHeader: mat.define-typography-level($font-family: "Poppins",
    $font-weight: 700,
    $font-size: 1.14rem,
    $line-height: 1.4em,
    $letter-spacing: normal,
  );

/* Merging the custom levels with Material Native levels to make them all available as classes */

$lcp-typography-config: map-merge(mat.define-typography-config(
      /* $headline: 96px */
      $headline: mat.define-typography-level($font-family: "Fira Sans Extra Condensed",
        $font-weight: 500,
        $font-size: 6.857rem,
        $letter-spacing: normal,
      ),
      /* $title: 60px  */
      $title: mat.define-typography-level($font-family: "Fira Sans Extra Condensed",
        $font-weight: 400,
        $font-size: 4.286rem,
        $letter-spacing: normal,
      ),
      /* $subheading-2: 48px */
      $subheading-2: mat.define-typography-level($font-family: "Fira Sans Extra Condensed",
        $font-weight: 700,
        $font-size: 3.429rem,
        $letter-spacing: normal,
      ),
      /* subheading-1: 34px */
      $subheading-1: mat.define-typography-level($font-family: "Fira Sans Extra Condensed",
        $font-weight: 400,
        $font-size: 2.429rem,
        $letter-spacing: normal,
      ),
      /* $body-1: 14px */
      $body-1: mat.define-typography-level($font-family: "Poppins",
        $font-weight: 400,
        $font-size: 1rem,
        $letter-spacing: normal,
      ),
      /* $body-2: 14px : Material bolder body, VTG specs are same as caption, and 14px has better accessibility in mind. */
      $body-2: mat.define-typography-level($font-family: "Poppins",
        $font-weight: 700,
        $font-size: 1rem,
        $letter-spacing: normal,
        $line-height: 1.4em,
      ),
      /* $button: 14px */
      $button: mat.define-typography-level($font-family: "Poppins",
        $font-weight: 500,
        $font-size: 1rem,
        $letter-spacing: normal,
      ),
      /* $caption: 12px*/
      $caption: mat.define-typography-level($font-family: "Poppins",
        $font-weight: 400,
        $font-size: 0.857rem,
        $letter-spacing: normal,
      ),
      /* $input: 14px */
      $input: mat.define-typography-level($font-family: "Poppins",
        $font-weight: 400,
        $font-size: 1rem,
        $line-height: 1.4em,
        $letter-spacing: normal,
      ),

      /* This classes must not be used, they are needed to define the complete Typography config. Only the Font Family has been adapted */
      $display-4: mat.define-typography-level("Fira Sans Extra Condensed"),
      $display-3: mat.define-typography-level("Fira Sans Extra Condensed"),
      $display-2: mat.define-typography-level("Fira Sans Extra Condensed"),
      $display-1: mat.define-typography-level("Fira Sans Extra Condensed"),
    ),
    ("subtitle-1": $subtitle-1,
      "subtitle-2": $subtitle-2,
      "custom-overline": $custom-overline,
      "custom-h5": $custom-h5,
      "custom-h6": $custom-h6,
      "tableHeader": $tableHeader ));



/* Include the common styles for Angular Material. We include this here so that you only
have to load a single css file for Angular Material in your app.
Be sure that you only ever include this mixin once!
@include mat.core();

Define the palettes for your theme using the Material Design palettes available in palette.scss
(imported above). For each palette, you can optionally specify a default, lighter, and darker
hue. Available color palettes: https://material.io/design/color/ */

$lcp-storybook-primary: mat.define-palette($lcp-blue, 700, 900);
$lcp-storybook-accent: mat.define-palette($lcp-yellow, 900);
/* The warn palette is optional (defaults to red). */
$lcp-storybook-warn: mat.define-palette($lcp-warn, 500, 300, 700);

/* Create the theme object. A theme consists of configurations for individual
theming systems such as "color" or "typography". */

$lcp-storybook-theme: mat.define-light-theme((color: (primary: $lcp-storybook-primary,
        accent: $lcp-storybook-accent,
        warn: $lcp-storybook-warn,
      ),
      typography: ($lcp-typography-config )));
$lcp-storybook-dark-theme: mat.define-dark-theme((color: (primary: $lcp-storybook-primary,
        accent: $lcp-storybook-accent,
        warn: $lcp-storybook-warn,
      ),
      typography: ($lcp-typography-config )));

.dark-mode {
  @include mat.all-component-colors($lcp-storybook-dark-theme);
}

/* Include theme styles for core and each component used in your app.
Alternatively, you can import and @include the theme mixins for each component
that you are using. */

@include mat.all-component-themes($lcp-storybook-theme);

/* Binding typography config to the material theme core, many elements will inherit the Typography, but some specific classes won't */

@include mat.core($lcp-typography-config);

/* Inheriting custom Typography levels as classes | Changing deep nested classes from Material components */

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  h1,
  .h1 {
    @include mat.typography-level($typography-config, 'headline');
  }

  h2,
  .h2 {
    @include mat.typography-level($typography-config, 'title');
  }

  h3,
  .h3 {
    @include mat.typography-level($typography-config, 'subheading-2');
  }

  h4,
  .h4 {
    @include mat.typography-level($typography-config, 'subheading-1');
  }

  h5,
  .h5 {
    @include mat.typography-level($typography-config, 'custom-h5');
  }

  h6,
  .h6 {
    @include mat.typography-level($typography-config, 'custom-h6');
  }

  .subtitle {
    @include mat.typography-level($typography-config, 'subtitle-1');
  }

  .subtitle-2 {
    @include mat.typography-level($typography-config, 'subtitle-2');
  }

  .overline {
    @include mat.typography-level($typography-config, 'custom-overline');
  }

  .body-2 {
    @include mat.typography-level($typography-config, 'body-2');
  }

  .caption {
    @include mat.typography-level($typography-config, 'caption');
  }

  .mat-sort-header-content {
    @include mat.typography-level($typography-config, 'tableHeader');
  }

  /* Add the classes used on material component that you would like to inherit the font */
  .body,
  .mat-option,
  .mat-tree,
  .mat-step-text-label,
  .mat-list-item,
  .mat-cell {
    @include mat.typography-level($typography-config, 'body-1');
  }

  .mat-select-trigger {
    @include mat.typography-level($typography-config, 'body-1');
  }

}

@include typography($lcp-storybook-theme);

/*
Setting up Primary and Accents from the palette
*/
$lcp-storybook-primary-500: mat.define-palette($lcp-blue, 500);
$lcp-storybook-primary-600: mat.define-palette($lcp-blue, 600);
$lcp-storybook-primary-700: mat.define-palette($lcp-blue, 700);
$lcp-storybook-primary-800: mat.define-palette($lcp-blue, 800);
$lcp-storybook-primary-900: mat.define-palette($lcp-blue, 900);

/**
*
* FOUNDATIONS
* Custom values for standard components used across the project
*
**/

// Cards specs
$lcpCardBorder: 0.071rem solid rgba(0, 0, 0, 0.12);

// Shadow value for cards
$lcpCardShadow: 0px 3px 4px rgba(0, 0, 0, 0.12);

// Shadow value for button
$lcpButtonShadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
